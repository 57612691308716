var body = document.querySelector('body');
var header = document.querySelector('[page-header]');
var header_height = header.offsetHeight;

var setBodyPadding = function() {
    if (header.offsetHeight !== parseInt(body.style.paddingTop)) {
        header_height = header.offsetHeight;
        body.style.paddingTop = header_height + 'px';
    }

    window.requestAnimationFrame(setBodyPadding);
}

window.requestAnimationFrame(setBodyPadding);